



















































import Vue from 'vue';
import axios from 'axios';

import DrugFacets from '@/facets/DrugFacets';
import Facet from '@/models/Facet';
import SearchableDrug from '@/models/SearchableDrug';

import FacetList from '@/components/FacetList.vue';
import DrugCard from '@/components/DrugCard.vue';
import SearchResultsToolbar from '@/components/SearchResultsToolbar.vue';
import Sidepanel from '@/components/Sidepanel.vue';
import { IDrugInfo } from '@/interfaces/IDrug';

export default Vue.extend({
  created() {
    this.$trackEvent('drug_view', {
      drug_id: this.$route.params.id,
    });
    this.tab = this.facets.findIndex((f: Facet) => f.name === this.$route.params.tab);
  },
  components: {
    FacetList,
    DrugCard,
    SearchResultsToolbar,
    Sidepanel,
  },
  data: () => ({
    facets: DrugFacets,
    drugInfo: {} as IDrugInfo,
    tab: 0,
  }),
  methods: {
    async getCurrentDrug(id: string) {
      const apiUrl = `${this.$apiPrefix}/drugs/${id}/info/`;
      try {
        const response = await axios.get(apiUrl);
        return response.data;
      } catch (err) {
        return null;
      }
    },
    updateTab(index: number): void {
      this.tab = index;
      this.$router.push({
        name: 'DrugSearchResults',
        params: {
          tab: this.facets[this.tab].name,
        },
      });
    },
  },
  async mounted() {
    this.$store.commit('sidepanel/close');
    this.drugInfo = await this.getCurrentDrug(this.$route.params.id);
    this.$store.commit('setEntity', {
      selectedSearchableEntity: new SearchableDrug({
        drug: this.drugInfo.drug,
        matching_drug: this.drugInfo.drug.name,
      }),
    });
  },
});
