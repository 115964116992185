



























































import Vue from 'vue';

import { IDrugInfo, IFdaXref } from '@/interfaces/IDrug';
import { IExternalSource } from '@/interfaces/IExternalSource';
import ExternalSource from './ExternalSource.vue';

export default Vue.extend({
  components: {
    ExternalSource,
  },
  computed: {
    chemblUrl(): string {
      return `https://www.ebi.ac.uk/chembl/compound_report_card/${this.drugInfo.drug.chembl_id}/`;
    },
  },
  methods: {
    drugBankUrl(drugbankId: string): string {
      return `https://go.drugbank.com/drugs/${drugbankId}`;
    },
    fdaUrl(applNo: number): string {
      return `https://www.accessdata.fda.gov/scripts/cder/daf/index.cfm?event=overview.process&ApplNo=${applNo}`;
    },
    // Hack: In order to now show dupplicate applicant:product pairs, we use the
    // heuristic that all applicants have a different product.
    mapFdaUrls(fdaXrefs: IFdaXref[]): IExternalSource[] {
      // UX hack: seeq.api output currently has separate entries for separate "products"
      // within the same "application" which makes the UX clunky. Heuristic: only show
      // the first product of each application.
      const outputUrls = [] as IExternalSource[];
      const applNos = [] as number[];
      // eslint-disable-next-line no-restricted-syntax
      for (const fdaXref of fdaXrefs) {
        if (applNos.includes(fdaXref.appl_no)) {
          // eslint-disable-next-line no-continue
          continue; // UX hack heuristic
        }
        applNos.push(fdaXref.appl_no);
        outputUrls.push({
          href: this.fdaUrl(fdaXref.appl_no),
          title: `${fdaXref.trade_name} (${fdaXref.applicant})`,
        });
      }
      return outputUrls;
    },
    // Fixme: Duplicated from DiseaseCard
    mapUrls(urlfunc: (id: string) => string, urls: string[]): IExternalSource[] {
      return urls.map((url: string) => ({
        href: urlfunc(url), title: url,
      }));
    },
  },
  props: {
    drugInfo: Object as () => IDrugInfo,
  },
  metaInfo() {
    let description = this.drugInfo.summary;
    if (description.length > 160) {
      description = description.substring(0, 157).concat('...');
    }
    return {
      meta: [{ name: 'description', content: description }],
    };
  },
});
